"use client";
import { useCallback, useEffect, useState } from "react";
import Script from "next/script";
import {
  Box,
  Collapse,
  LinearProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import JSIcon from "mdi-react/LanguageJavascriptIcon";
import PyIcon from "mdi-react/LanguagePythonIcon";
import Bytez from "bytez.js";
import Editor from "@monaco-editor/react";

import { useStore, observer } from "../../../service/mobx";
import useIO from "../../model/Content/Play/InputBox/useIO";
import defaultInputs from "../../model/Content/Play/InputBox/defaultInputs.json";
import Tabs from "../../../component/Tabs";
import ButtonFilled from "../../../component/Button/Outlined";
import ToolTip from "../../../component/Tooltip";
import useSignInDialog from "../../../component/Dialog/dialogs/appWide/Login";

const STARTING_INDENTS = / {8}/g;
const AsyncFunction = Object.getPrototypeOf(async function () {}).constructor;

function WidgetClient({ count, models }) {
  const { analytics, device, snackbar, user, utilities } = useStore();
  const [language, setLanguage] = useState(
    () => utilities.cookie.get("lang") || "javascript"
  );
  const [model, setModel] = useState(models[0]);
  const [code, setCode] = useState();
  const [cluster, setCluster] = useState();
  const [pythonLoading, setPythonLoading] = useState(
    typeof window === "undefined" ? true : !window.pyodideShell
  );
  const [notClicked, setNotClicked] = useState(true);
  const signInDialog = useSignInDialog(`Run ${count} models for free`);
  const maxHeight = 480;

  useEffect(() => {
    if (user.key) {
      const bytez = new Bytez(user.key, process.env.NODE_ENV === "development");
      const clusters = models.map(model => bytez.model(model.modelId));

      for (const cluster of clusters) {
        cluster.create({ capacity: { min: 0, desired: 1, max: 1 } });
      }

      const deleteAllClusters = () =>
        clusters.forEach(cluster => cluster.delete());

      window.addEventListener("beforeunload", deleteAllClusters);

      return () => {
        deleteAllClusters();
        window.removeEventListener("beforeunload", deleteAllClusters);
      };
    }
  }, [models, user.key]);

  useEffect(() => {
    if (user.loaded) {
      setCode(
        getCode(
          user.key,
          language,
          model.task,
          model.comment,
          model.modelId,
          model.code,
          model.closed
        )
      );
    }
  }, [
    user,
    user.key,
    user.loaded,
    language,
    model.task,
    model.comment,
    model.modelId,
    model.code,
    model.closed
  ]);

  useEffect(() => {
    if (user.key) {
      if (model.closed) {
        setCluster({ status: "ready", modelId: model.modelId });
      } else {
        return user.data.query(
          { collection: "clusters", modelId: model.modelId, limit: 1 },
          ({ docs: [doc] }) => setCluster(doc?.data())
        );
      }
    }
  }, [user, user.key, model.modelId, model.closed]);

  useEffect(() => {
    if (notClicked) {
      const toNextModel = model => {
        let i = models.length;

        while (i--) {
          if (models[i] === model) {
            break;
          }
        }

        return models[i + 1] || models[0];
      };

      const id = setInterval(setModel, 4e3, toNextModel);

      return () => clearInterval(id);
    }
  }, [models, setModel, notClicked]);

  const runModel = useCallback(async () => {
    try {
      snackbar.notify({ text: "Running inference, please wait..." });

      model.running = true;

      setModel({ ...model });

      if (language === "javascript") {
        model.output = await new AsyncFunction("Bytez", code)(Bytez);
      } else {
        await window.pyodideShell.runPythonAsync(code);

        model.output = window.pyodideShell.globals.get("results").toJs();
      }

      setModel(currentModel => {
        if (currentModel.nickName === model.nickName) {
          return { ...model };
        } else {
          setTimeout(() => {
            snackbar.notify({
              line1: model.nickName,
              line2: "---",
              line3: "Inference has completed.",
              actions: [
                { label: "View output", onClick: () => setModel(model) }
              ]
            });
          }, 100);

          return currentModel;
        }
      });
    } finally {
      setModel(currentModel =>
        currentModel.nickName === model.nickName
          ? { ...model, running: false }
          : currentModel
      );

      analytics.track.event("Model Run", { modelId: model.modelId });
    }
  }, [analytics, snackbar, language, code, model]);

  // console.log({ cluster, model, pythonLoading });

  return device.measured ? (
    <Box px={{ compact: 2, expanded: 0 }}>
      <Box
        border={1}
        overflow="hidden"
        boxShadow="var(--elevation-1)"
        borderRadius="var(--shape-sm)"
        onClick={() => setNotClicked(false)}
        borderColor="var(--outline-variant)"
        bgcolor="var(--dark-surface-container-high)"
        height={device.isPhone ? undefined : maxHeight}
      >
        <ModelSelector
          setModel={setModel}
          models={models}
          model={model}
          isPhone={device.isPhone}
        />
        <Stack
          useFlexGap
          direction={{ compact: "column", expanded: "row" }}
          spacing={2}
          bgcolor="var(--dark-surface-container-lowest)"
          p={{ compact: 1, expanded: 0 }}
          px={{ compact: 1, expanded: 2 }}
          // max height minus the model selection
          height={{ compact: "unset", expanded: `calc(${maxHeight}px - 48px)` }}
        >
          <Stack spacing={1} width={{ compact: "100%", expanded: "50%" }}>
            <Header
              model={model}
              language={language}
              setLanguage={setLanguage}
              setCode={setCode}
              userKey={user.key}
              pythonLoading={pythonLoading}
              clusterStatus={cluster?.status}
              utilities={utilities}
            />
            <Collapse mountOnEnter unmountOnExit in={model.running === true}>
              <LinearProgress />
            </Collapse>
            <CodeBox
              code={code}
              model={model}
              language={language}
              setCode={setCode}
              maxHeight={maxHeight}
            />
            <Footer
              isPhone={device.isPhone}
              running={model.running}
              runModel={user.isAnonymous ? signInDialog : runModel}
            />
          </Stack>
          <OutputBox isPhone={device.isPhone} model={model} />
        </Stack>
      </Box>
      <Script
        id="pyodide"
        src="https://cdn.jsdelivr.net/pyodide/v0.27.4/full/pyodide.js"
        onLoad={async () => {
          if (window.pyodideShell === undefined) {
            const shell = await window.loadPyodide();

            await shell.loadPackage("micropip");

            const micropip = await shell.pyimport("micropip");
            // Install bytez from PyPI
            await micropip.install("bytez");

            window.pyodideShell = shell;
            setPythonLoading(false);
          }
        }}
      />
    </Box>
  ) : null;
}

export default observer(WidgetClient);

function OutputBox({ isPhone, model }) {
  const { Input, Output } = useIO(model.task);

  return Input || Output ? (
    <Box
      height="100%"
      width={{ compact: "100%", expanded: "50%" }}
      bgcolor="var(--dark-surface-container-low)"
      sx={{
        pb: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        "& .MuiStack-root": {
          [model.task === "chat" ? undefined : "p"]: 0
        },
        "& .monaco-editor .margin, .monaco-editor-background": {
          bgcolor: "var(--dark-surface-container-low) !important"
        },
        "& img": {
          maxHeight: 320
        }
      }}
    >
      {model.task === "chat" ? (
        <VsCode
          language="json"
          height={isPhone ? 320 : undefined}
          options={{ readOnly: true, domReadOnly: true }}
          value={
            model.output ? JSON.stringify(model.output, undefined, "  ") : ""
          }
        />
      ) : Output ? (
        <Output output={model.output?.output ?? model.output} />
      ) : null}
    </Box>
  ) : null;
}
const Header = ({
  model,
  language,
  setLanguage,
  userKey,
  setCode,
  pythonLoading,
  clusterStatus,
  utilities
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    pt={1}
    pl={1}
  >
    <Stack spacing={1} direction="row" alignItems="center">
      <ToggleButtonGroup
        exclusive
        size="small"
        value={language}
        color="warning"
        onChange={(_, language) => {
          const newLanguage = language || "javascript";

          setLanguage(newLanguage);
          setCode(
            getCode(
              userKey,
              newLanguage,
              model.task,
              model.comment,
              model.modelId,
              model.code,
              model.closed
            )
          );
          utilities.cookie.set("lang", newLanguage);
        }}
      >
        <ToggleButton value="python" disabled={pythonLoading}>
          <PyIcon
            size={20}
            color={
              pythonLoading
                ? "var(--dark-surface-container-highest)"
                : language === "python"
                ? undefined
                : "var(--dark-surface-on-variant)"
            }
          />
        </ToggleButton>
        <ToggleButton value="javascript">
          <JSIcon
            size={20}
            color={
              language === "javascript"
                ? undefined
                : "var(--dark-surface-on-variant)"
            }
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
    <ClusterStatus clusterStatus={clusterStatus} />
  </Stack>
);
const Footer = ({ isPhone, runModel, running }) => (
  <Stack direction="row" maxHeight={56} width="100%" justifyContent="flex-end">
    <ButtonFilled
      size="small"
      fullWidth={isPhone}
      label="Run code"
      disabled={running}
      onClick={runModel}
      color="var(--dark-primary-color)"
      iconColor="var(--dark-surface-on-color)"
    />
  </Stack>
);

const CodeBox = ({ maxHeight, setCode, code, language, model }) => (
  <Box
    sx={{
      overflow: "hidden",
      borderRadius: "var(--shape-sm)",
      transition: "all 200ms var(--motion-easing-emphasized)",
      "& *": {
        fontFeatureSettings: '"liga" 1, "calt" 1'
      },
      "& .iPadShowKeyboard": {
        display: "none"
      },
      "& .monaco-editor .margin, .monaco-editor-background": {
        bgcolor: "var(--dark-surface-container-lowest) !important"
      },
      "&:hover": {
        boxShadow: "var(--dark-primary-color) 0px 0px 0px 2px"
      }
    }}
  >
    <VsCode
      value={code}
      language={language}
      // height = maxHeight of the component - model selection - header - footer
      height={`calc(${maxHeight || 320}px - 48px - 44px - 56px)`}
      onChange={code => {
        model.code[language] = code;
        setCode(code);
      }}
      beforeMount={monaco => {
        monaco.languages.typescript.javascriptDefaults.addExtraLib(
          `
            // You can declare it however you want:
            // for instance, if Bytez is a constructor, you might do:
            declare class Bytez {
              constructor(apiKey: string, devMode?: boolean);
              model(id: string): any;
            }
            // Or simpler: declare var Bytez: any;
          `,
          "file:///bytez-globals.d.ts"
        );
      }}
    />
  </Box>
);

function getCode(userKey, language, task, comment, modelId, modelCode, closed) {
  if (modelCode[language] === undefined) {
    const key = userKey || "Sign in for free key";
    const input = JSON.stringify(defaultInputs[task]);
    const closedSource = closed ? ", provider" : "";
    const codeComment = `
    ${comment}

  Edit this code, see for yourself`
      .replace(STARTING_INDENTS, "")
      .trim();

    switch (language) {
      case "javascript": {
        var code = `
        /*
          ${codeComment}
        */

        const client = new Bytez("${key}"${
          process.env.NODE_ENV === "development" ? ", true" : ""
        })
        const modelId = "${modelId}"

        const model = client.model(modelId)

        // set your model input
        const input = ${input}

        // and get results
        const { error, output${closedSource} } = await model.run(input)

        return { error, output${closedSource} };
      `;

        break;
      }
      case "python": {
        code = `
        """
          ${codeComment}
        """

        from bytez import Bytez

        client = Bytez("${key}"${
          process.env.NODE_ENV === "development" ? ", True" : ""
        })
        modelId = "${modelId}"

        model = client.model(modelId)

        # set your model input
        input = ${input}

        # and get results
        output, error = model.run(input)

        print(output, error)

        results = { "error": error, "output": output }
      `;

        break;
      }
    }

    modelCode[language] = code.replace(STARTING_INDENTS, "").trim();
  }

  return modelCode[language];
}
function ModelSelector({ models, setModel, model, isPhone }) {
  const [tabs] = useState(
    models.map(model => ({
      label: model.nickName,
      onClick: () => setModel(model),
      sx: {
        fontSize: { compact: 18, expanded: 16 },
        color: "var(--dark-surface-on-variant)",
        "& :hover": {
          borderRadius: "var(--shape-xs-top)"
        }
      }
    }))
  );

  return (
    <Tabs
      noOutline
      tabs={tabs}
      borderRadius={0}
      variant={isPhone ? "scrollable" : undefined}
      sx={{
        borderBottom: 0,
        "& .Mui-selected, & :hover": {
          color: "var(--dark-primary-color) !important"
        },
        "& .MuiTabs-indicator": {
          borderRadius: "var(--shape-round)",
          bgcolor: "var(--dark-primary-color)"
        }
      }}
      override={tabs.findIndex(tab => tab.label === model.nickName).toString()}
    />
  );
}
export const VsCode = ({ options, ...props }) => (
  <Editor
    theme="vs-dark"
    options={{
      wordWrap: "on",
      fontSize: 12,
      automaticLayout: true,
      scrollBeyondLastLine: false,
      minimap: { enabled: false },
      stickyScroll: { enabled: false },
      lineNumbersMinChars: 2,
      fontFamily:
        "'Fira Code', 'Fira Code Fallback', Consolas, Monaco, 'Andale Mono', 'Lucida Console', monospace",
      ...options
    }}
    {...props}
  />
);

export const ClusterStatus = ({ clusterStatus }) => (
  <ToolTip title={clusterStatus || "Cluster off"}>
    <Box
      width={16}
      height={16}
      sx={{ mixBlendMode: "screen", opacity: 0.9 }}
      borderRadius="var(--shape-round)"
      bgcolor={`var(--colors-${
        clusterStatus === undefined
          ? "error-50"
          : clusterStatus === "instance on" || clusterStatus === "ready"
          ? "success-60"
          : "warning-90"
      })`}
    />
  </ToolTip>
);
